import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import * as Utils from '../../../utils/env';
import './style.css';
import { debounce } from 'lodash';
import {
  AcreageCategory,
  DEFAULT_PRODUCT_FILTER,
  DirectionTypes,
  PriceCategory,
  StatusProducts,
  TypeProducts,
  billion,
} from 'helpers/config';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Drawer,
  InputNumber,
  Table,
  Checkbox,
  Upload,
  Modal,
  Spin,
  message,
  Progress, Collapse, CollapseProps
} from 'antd';
import type { SearchProps } from 'antd/es/input/Search';
import { PlusOutlined } from '@ant-design/icons';
import {
  addProduct,
  getMeInfo,
  getProductPaging,
  getMyProductPaging,
  removeFile,
  updateProduct,
  uploadFile,
  uploadMultipleFile,
  getCities,
  getDistricts,
  getWards
} from 'repositories';
import type {
  GetProp,
  TableProps,
  CheckboxProps,
  UploadFile,
  UploadProps,
} from 'antd';
import { IMediaFile, IProduct, IAddress, IOwner } from './interface';
import { UploadChangeParam } from 'antd/es/upload';
import { ICity, IDistrict, IWard } from 'interfaces/ICommon';
import * as opencage from 'opencage-api-client';

interface IProps { }

type ColumnsType<T> = TableProps<T>['columns'];
type TablePaginationConfig = Exclude<
  GetProp<TableProps, 'pagination'>,
  boolean
>;

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const columns: ColumnsType<any> = [
  {
    title: 'Tên nguồn',
    dataIndex: 'name',
    sorter: true,
    // render: (name) => `${name.first} ${name.last}`,
    width: '40%',
  },
  {
    title: 'Diện tích',
    dataIndex: 'acreage',
  },
  {
    title: 'Giá',
    dataIndex: 'priceFormat',
  },
  {
    title: 'Ngày đăng',
    dataIndex: 'createdAt',
    render: (date) => `${Utils.getDateShort(date, 'DD-MM-YYYY HH:mm')}`,
  },
];

const initProduct: IProduct = {
  name: '',
  shortDescription: '',
  description: '',
  price: null,
  priceSale: null,
  priceUnit: null,
  priceFormat: null,
  isPriceChange: true,
  acreage: '',
  width: null,
  height: null,
  unitAcreageType: 0,
  addressId: null,
  address: { cityId: 50, countryId: 1, districtId: 1610 } as IAddress,
  order: 0,
  typeShow: 0,
  produtMediaFiles: [],
  productTransactionId: 0,
  feePercent: null,
  fee: null,
  feeUnit: null,
  employeeId: null,
  employee: null,
  owner: null,
  ownerId: null,
  status: 1,
  typeProduct: null,
  directionType: null,
  seriNumber: null,
  statusSale: null,
  property: null,
};

const { Search } = Input;

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const ProductManagement: React.FC<IProps> = (props) => {
  const [searchText, setSearchText] = useState<string>('');
  const [products, setProducts] = useState<Array<IProduct>>([]);
  const [selectedProduct, setSelectedProduct] = useState<IProduct>(null);
  const [open, setOpen] = useState(false);
  const [openModalWarning, setOpenModalWarning] = useState(false);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [needRemoveFiles, setNeedRemoveFiles] = useState<Array<IMediaFile>>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [totalItem, setTotalItem] = useState<number>(0);
  /** Temp file using for cached and delete in db */
  const [tempFiles, setTempFiles] = useState<Array<IMediaFile>>([]);
  const [cities, setCities] = useState<Array<ICity>>([]);
  const [districts, setDistricts] = useState<Array<IDistrict>>([]);
  const [wards, setWards] = useState<Array<IWard>>([]);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });
  const [uploading, setUploading] = useState<boolean>(false);
  const [progress, setProgress] = useState<{ [key: string]: number | null }>(
    {}
  );

  const pureDataRef = useRef<any>();

  useEffect(() => {
    onGetProductList();
  }, [JSON.stringify(tableParams), reload]);

  const onGetCities = async (countryId) => {
    let params = {
      countryId: countryId,
    };
    const rsp = await getCities(params);
    if (rsp.data.data) {
      var data = rsp.data.data.map(item => {
        item.label = item.name;
        item.value = item.id;
        return item;
      });
      setCities(data);
    }
  }

  const onGetDistricts = async (cityId) => {
    let params = {
      cityId: cityId,
    };
    const rsp = await getDistricts(params);
    if (rsp.data.data) {
      var data = rsp.data.data.map(item => {
        item.label = item.name;
        item.value = item.id;
        return item;
      });
      setDistricts(data);
    }
  }
  const onGetWard = async (districtId) => {
    let params = {
      districtId: districtId,
    };
    const rsp = await getWards(params);
    if (rsp.data.data) {
      var data = rsp.data.data.map(item => {
        item.label = item.name;
        item.value = item.id;
        return item;
      });
      setWards(data);
    }
  }

  const onGetProductList = async (params?: any) => {
    setLoading(true);
    try {
      let args = !!params
        ? params
        : {
          page: tableParams?.pagination?.current ?? 1,
          page_size: tableParams?.pagination?.pageSize ?? 20,
        };
      var rsp = null;
      if (document.location.pathname == "/admin/myproducts/management")
        rsp = await getMyProductPaging(args);
      else
        rsp = await getProductPaging(args);

      if (rsp && !!rsp?.data?.data?.data) {
        const newData = rsp?.data?.data?.data?.map((item) => ({
          ...item,
          priceFormat: Utils.convertToBillion(item.price, true, 'Triệu'),
        }));

        let result = newData;

        setTotalItem(rsp?.data?.data?.totalCount);
        setProducts(result ?? []);
      } else {
        setProducts([]);
      }
    } catch (error) {
    } finally {
      reload && setReload(false);
      setLoading(false);
    }
  };

  const getCoordinates = async (product: IProduct) => {
    let address = product.address
    let district = districts.find(_ => _.id == address?.districtId)
    let ward = wards?.find(_ => _.id == address?.wardId)
    let city = cities?.find(_ => _.id == address?.cityId)
    let addressFiltler = `${address.street}`
    if (ward) {
      addressFiltler += `, ${ward.name}`
    }
    if (district) {
      console.log('district: ', district)
      if (district.name == 'Quận 9')
        district.name = 'Thành phố Thủ Đức'
      addressFiltler += `, ${district.name}`
    }
    if (city) {
      addressFiltler += `, ${city.name}`
    }

    let apiKey = '2623a4c6244e47b1be46a1fd2e72b7c0'
    try {
      let results = [];

      const rsp = await opencage.geocode({ key: apiKey, q: addressFiltler })
      const { geometry, formatted } = rsp.results[0];
      results.push({ input: address, geometry, formatted });

      if (results.length > 0) {
        const place = results[0];
        // console.log(place.formatted);
        // console.log(place.geometry);
        // console.log(place.annotations.timezone.name);
        let address = selectedProduct.address
        address.latitude = place?.geometry?.lat
        address.longitude = place?.geometry?.lng
        setSelectedProduct({ ...selectedProduct })
      }

      // const response = await fetch(
      //   `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(addressFiltler)}&format=json&limit=1`
      // );
      // const data = await response.json();
      // console.log('data: ', data)
      // if (data.length > 0) {
      //   let address = selectedProduct.address
      //   address.latitude = data[0].lat
      //   address.longitude = data[0].lon
      //   console.log('data[0].lat: ', data[0].lat);
      //   console.log('data[0].lon: ', data[0].lon);
      //   setSelectedProduct({ ...selectedProduct })
      // } else {
      // }
    } catch (error) {
    }
  };

  const onSearch: SearchProps['onSearch'] = (value, _e, info) => { };

  const performSearch = (text: string) => {
    onGetProductList({ query: text, page: 1, page_size: 20 });
  };

  // Create a debounced version of the performSearch function
  const debouncedSetSearch = debounce(setSearchText, 500);
  const debouncedSearch = debounce(performSearch, 500);

  /** Has input data */
  const hasInputData = useMemo(
    () =>
      JSON.stringify(pureDataRef?.current) !== JSON.stringify(selectedProduct),
    [selectedProduct]
  );

  const onSearchTextChange = (value: string) => {
    debouncedSetSearch(value);
    debouncedSearch(value);
  };

  const showDrawer = () => {
    setOpen(true);
    setSelectedProduct(initProduct);
    onGetCities(initProduct.address.countryId);
    onGetDistricts(initProduct.address.cityId);
    onGetWard(initProduct.address.districtId);
    pureDataRef.current = initProduct;
  };

  const onClose = (isSkipWarning?: boolean) => {
    if (hasInputData && !isSkipWarning) {
      setOpenModalWarning(true);
    } else {
      setOpen(false);
      setIsSubmitting(false);
      setNeedRemoveFiles([]);
      if (!!pureDataRef?.current) pureDataRef.current = null;
      if (!!selectedProduct) setSelectedProduct(null);
      // if (!!tempFiles?.length && usingUploadNew) onRemoveFile(tempFiles);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    // if (!usingUploadNew) {
    //   const needToUploadFiles = selectedProduct?.produtMediaFiles?.filter(
    //     (_) => !_?.url && !!_?.originFileObj
    //   );

    //   const oldFiles = selectedProduct?.produtMediaFiles?.filter(
    //     (_) => !!_?.url
    //   );
    //   let newMediaFiles = [...oldFiles];

    //   /** Upload new file to server */
    //   if (!!needToUploadFiles?.length) {
    //     const newFiles = await onUploadFile(needToUploadFiles);
    //     newMediaFiles = newMediaFiles.concat(newFiles);
    //   }

    //   /** Remove file from server */
    //   if (!!needRemoveFiles?.length) {
    //     onRemoveFile();
    //   }

    //   const newProduct = {
    //     ...selectedProduct,
    //     produtMediaFiles: [...newMediaFiles],
    //   };
    //   onSubmit(newProduct);
    // } else {

    // if (!!tempFiles?.length) onRemoveFile(tempFiles);

    onSubmit();
    // }
  };

  const onUploadFile = async (fileList: Array<IMediaFile>) => {
    let newFiles = [];
    for (let i = 0; i < fileList?.length; i++) {
      setSelectedProduct((prev) => ({
        ...prev,
        produtMediaFiles: prev.produtMediaFiles?.map((_) => {
          if (_?.uid === fileList[i]?.uid) {
            return { ..._, status: 'uploading' };
          }
          return { ..._ };
        }),
      }));
      const formData = new FormData();

      /** Compress file */
      // const fileCompressed = await compressFile(fileList[i]?.originFileObj)

      // if(instanceof fileCompressed === File){

      // } else {

      // }
      // formData.append('file', fileCompressed);

      formData.append('file', fileList[i]?.originFileObj);

      const rsp: any = await uploadFile(formData);
      const fileUploaded = { ...rsp?.data, status: 'done' };
      delete fileUploaded.id;

      newFiles.push(fileUploaded);
    }

    return newFiles;
  };

  const onRemoveFile = async (params?: Array<IMediaFile>) => {
    const newList = !!params ? params : needRemoveFiles;
    for (let i = 0; i < newList?.length; i++) {
      if (!!newList[i]?.url?.length) {
        const rsp = await removeFile({ fileUrl: newList[i]?.url });
        if (!!rsp) {
          setSelectedProduct((pre) => ({
            ...pre,
            produtMediaFiles: pre?.produtMediaFiles?.filter(
              (_) => _?.name !== newList[i]?.name
            ),
          }));
        }
      }
    }
  };

  const onSubmit = async (newProduct?: IProduct) => {
    try {
      if (!!selectedProduct?.id) {
        const args = !!newProduct ? newProduct : selectedProduct;
        const rsp = await updateProduct(args);
        message.success('Cập nhật thành công');
        // if(rsp && !rsp?.data?.error){

        // }
      } else {
        const args = !!newProduct ? newProduct : selectedProduct;
        const rsp = await addProduct(args);
        message.success('Thêm thành công');
      }
    } catch (error) {
    } finally {
      setOpen(false);
      setIsSubmitting(false);
      setReload(true);
    }
  };

  const handleTableChange: TableProps['onChange'] = (
    pagination,
    filters,
    sorter
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setProducts([]);
    }
  };

  const handleChangeAddress = (field: keyof IAddress, value) => {
    var addressProduct = selectedProduct.address;
    addressProduct = addressProduct == null ? {} as IAddress : addressProduct;
    if (field == "cityId") {
      addressProduct[field] = value;
      if (value == null) {
        onGetDistricts(-1);
        onGetWard(-1);
      }
      else
        onGetDistricts(value);
      addressProduct.districtId = null;
      addressProduct.wardId = null;
    }
    if (field == "districtId") {
      addressProduct[field] = value;
      if (value == null)
        onGetWard(-1);
      else
        onGetWard(value);
      addressProduct.wardId = null;
    }
    if (field == "wardId") {
      addressProduct[field] = value;
    }
    if (field == "street") {
      addressProduct[field] = value;
    }

    setSelectedProduct((pre) => ({
      ...pre,
      ['address']: addressProduct as IAddress,
    }));
  };

  const handleChangeOwner = (
    field: keyof IOwner,
    fieldAddress: keyof IAddress,
    value
  ) => {
    var owner = selectedProduct?.owner;
    value = value.target.value;
    if (field == 'address') {
      value = {
        [fieldAddress]: value,
      };
    }

    if (owner == null) owner = {} as IOwner;
    owner[field] = value;

    setSelectedProduct((pre) => ({
      ...pre,
      ['owner']: owner as IOwner,
    }));
  };

  const handlePageChange = (page, pageSize) => {
    setTableParams((pre) => ({
      ...pre,
      pagination: { ...pre?.pagination, pageSize, current: page },
    }));
  };

  const handleChangeField = (field: keyof IProduct, value: any) => {
    if (!!field) {
      let acreage = selectedProduct?.acreage;

      switch (field) {
        case 'width':
        case 'height':
          // acreage =
          //   field === 'height'
          //     ? `${selectedProduct?.width * value}`
          //     : `${selectedProduct?.height * value}`;
          break;
        case 'price':
          value = Utils.convertToNumber(value.target.value, 'tỷ');
          break;
        case 'priceSale':
          value = Utils.convertToNumber(value.target.value, 'tỷ');
          break;
        case 'fee':
          value = Utils.convertToNumber(value.target.value, 'triệu');
          break;
        default:
          break;
      }

      // if (['width', 'height'].includes(field)) {
      //   acreage =
      //     field === 'height'
      //       ? `${selectedProduct?.width * value}`
      //       : `${selectedProduct?.height * value}`;
      // }
      // if (field == 'price') {
      //   value = value.target.value;
      //   console.log(value);
      //   value = Utils.convertToNumber(value, 'tỷ');
      //   console.log(value);
      // }
      // var unitFree = 'Triệu';
      // if (field == 'fee') {
      //   value = value.target.value;
      //   console.log(value);
      //   value = Utils.convertToNumber(value, 'triệu');
      //   console.log(value);
      // }
      setSelectedProduct((pre) => ({
        ...pre,
        [field]: value,
        // acreage: acreage,
      }));
    }
  };

  const handleCancel = () => setPreviewOpen(false);

  const customRequest = async ({
    file,
    onSuccess,
    onError,
    onProgress,
  }: any) => {
    try {
      // Set uploading state to true
      setUploading(true);

      // Your custom upload logic here, for example, using fetch or Axios
      const formData = new FormData();
      formData.append('file', file);

      const rsp: any = await uploadFile(formData);
      const fileUploaded = { ...rsp?.data, status: 'done' };
      delete fileUploaded.id;

      if (!!rsp && !rsp?.error) {
        onSuccess();
        // message.success(`${file.name} file uploaded successfully`);

        // Update progress status for this file
        setProgress((prevProgress) => ({
          ...prevProgress,
          [file.uid]: 100, // Set progress to 100% when upload is successful
        }));

        const fileFound = selectedProduct?.produtMediaFiles?.some(
          (_) => _?.name === file?.name
        );

        setTempFiles((pre) => [...pre, fileUploaded]);

        if (fileFound) {
          setSelectedProduct((pre) => ({
            ...pre,
            produtMediaFiles: [
              ...pre?.produtMediaFiles?.filter((_) => _?.name !== file?.name),
              fileUploaded,
            ],
          }));
        } else {
          setSelectedProduct((pre) => ({
            ...pre,
            produtMediaFiles: [...pre?.produtMediaFiles, fileUploaded],
          }));
        }
      } else {
        onError();
        message.error(`${file.name} file upload failed.`);

        // Update progress status for this file
        setProgress((prevProgress) => ({
          ...prevProgress,
          [file.uid]: 'error', // Set progress to 'error' when upload fails
        }));
      }
    } catch (error) {
      onError();
      message.error(`${file.name} file upload failed: ${error.message}`);

      // Update progress status for this file
      setProgress((prevProgress) => ({
        ...prevProgress,
        [file.uid]: 'error', // Set progress to 'error' when upload fails
      }));
    } finally {
      // Set uploading state to false after upload is completed
      setUploading(false);
    }
  };

  const handleChange = async (info: UploadChangeParam<any>) => {
    const { file } = info;
    if (file.status === 'uploading') {
      setProgress((prevProgress) => ({
        ...prevProgress,
        [file.uid]: file.percent,
      }));
    } else if (!!file?.url) {
      /** Remove file */
      const rsp = await removeFile({ fileUrl: file?.url });
      if (!!rsp) {
        setSelectedProduct((p) => ({
          ...p,
          produtMediaFiles: p?.produtMediaFiles?.filter(
            (f) => f?.name !== file?.name
          ),
        }));
      }
    }
  };

  const beforeUpload = (file, fileList) => { };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Thêm</div>
    </button>
  );

  const _renderSearchBar = () => {
    return (
      <div className="main-wrapper__search-bar">
        <Search
          placeholder="Tìm kiếm theo tên"
          allowClear
          onSearch={onSearch}
          onChange={(e) => onSearchTextChange(e?.target?.value)}
          style={{ width: '55vw' }}
        />
        <Button
          onClick={showDrawer}
          style={{ background: '#5daebf', color: 'white', height: "38px" }}
          icon={<PlusOutlined />}
        >
          Thêm nguồn
        </Button>
      </div>
    );
  };

  const _renderContent = () => {
    return (
      <Spin spinning={loading} tip="Đang tải dữ liệu...">
        <Table
          style={{ marginTop: '24px' }}
          scroll={{ y: '70vh' }}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={products}
          pagination={{
            total: totalItem,
            current: tableParams?.pagination?.current,
            pageSize: tableParams?.pagination?.pageSize,
            onChange: handlePageChange,
            // showSizeChanger: true,
            // pageSizeOptions: ['10', '20', '30'], // Customize page size options
            // showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          }}
          loading={loading}
          onChange={handleTableChange}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (!!record?.id) {
                  record.address = record.address == null ? {} : record.address;
                  onGetCities(record.address.countryId);
                  onGetDistricts(record.address.cityId);
                  onGetWard(record.address.districtId);
                  setSelectedProduct(record);
                  pureDataRef.current = record;
                  setOpen(true);
                }
              }, // click row
            };
          }}
        />
      </Spin>
    );
  };

  const filterOption = (input: string, option?) =>
    (Utils.removeAccents(option?.label) ?? '').includes(Utils.removeAccents(input));



  const _renderMediaFiles = () => {
    return (
      <>
        <Upload
          listType="picture-card"
          fileList={selectedProduct?.produtMediaFiles as any}
          customRequest={customRequest}
          onChange={handleChange}
          beforeUpload={beforeUpload}
          multiple
        >
          {uploadButton}
        </Upload>
        {uploading && (
          <Progress
            percent={Math.min(
              ...Object.values(progress).filter((value) => value !== null)
            )}
          />
        )}
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </>
    );
  };

  const _renderDrawer = () => {
    const title = !!selectedProduct?.id
      ? 'Cập nhật sản phẩm'
      : 'Tạo sản phẩm';

    const info = <>
      <Row gutter={24}>
        <Col span={24}>
          <span className="text-black font-semibold">Tên sản phẩm</span>
          <Input
            placeholder="Nhập tên sản phẩm"
            value={selectedProduct?.name}
            onChange={(e) => handleChangeField('name', e?.target?.value)}
          />
        </Col>
      </Row>
      <Row gutter={24} className="mt-4">
        <Col span={7}>
          <span className="text-black font-semibold">Ngang</span>
          <InputNumber
            addonAfter="m"
            value={selectedProduct?.width}
            style={{ width: '100%' }}
            placeholder="Chiều ngang"
            onChange={(e) => {
              handleChangeField('width', e);
            }}
          />
        </Col>
        <Col span={7}>
          <span className="text-black font-semibold">Dài</span>
          <InputNumber
            addonAfter="m"
            value={selectedProduct?.height}
            style={{ width: '100%' }}
            placeholder="chiều dài"
            onChange={(e) => {
              handleChangeField('height', e);
            }}
          />
        </Col>
        <Col span={10}>
          <span className="text-black font-semibold">Diện tích</span>
          <InputNumber
            addonAfter="m2"
            value={selectedProduct?.acreage}
            style={{ width: '100%' }}
            placeholder="Nhập diện tích"
            onChange={(e) => {
              handleChangeField('acreage', e);
            }}
          />
        </Col>
      </Row>
      <Row gutter={24} className="mt-4">
        <Col span={8}>
          <span className="text-black font-semibold">Giá</span>
          <InputNumber
            addonAfter={'Tỷ'}
            value={Utils.convertToBillion(selectedProduct?.price, false)}
            onBlur={(e) => {
              handleChangeField('price', e);
            }}
            style={{ width: '100%' }}
            placeholder="Giá"
          />
        </Col>
        <Col span={8}>
          <span className="text-black font-semibold">Giá giảm</span>
          <InputNumber
            addonAfter={'Tỷ'}
            value={Utils.convertToBillion(selectedProduct?.priceSale, false)}
            onBlur={(e) => {
              handleChangeField('priceSale', e);
            }}
            style={{ width: '100%' }}
            placeholder="Giá giảm"
          />
        </Col>
        <Col span={8} className="mt-5">
          <Checkbox
            checked={selectedProduct?.isPriceChange}
            onChange={(e) =>
              handleChangeField('isPriceChange', e?.target?.checked)
            }
          >
            Thương lượng
          </Checkbox>
        </Col>
      </Row>
      <Row gutter={24} className="mt-4">
        <Col span={14}>
          <span className="text-black font-semibold">Phí giao dịch</span>
          <InputNumber
            addonAfter={Utils.getUnitCurrency(selectedProduct?.fee)}
            value={Utils.convertMilionToBilion(selectedProduct?.fee)}
            style={{ width: '100%' }}
            placeholder="Phí giao dịch"
            onBlur={(e) => handleChangeField('fee', e)}
            onChange={(e) => { }}
          />
        </Col>
        <Col span={10}>
          <span className="text-black font-semibold">Phí giao dịch %</span>
          <InputNumber
            addonAfter="%"
            value={selectedProduct?.feePercent}
            style={{ width: '100%' }}
            placeholder="Phí giao dịch %"
            onChange={(e) => {
              handleChangeField('feePercent', e);
            }}
          />
        </Col>
      </Row>
      <Row gutter={24} className="mt-4">
        <Col span={12}>
          <span className="text-black font-semibold">Số sổ</span>
          <Input
            style={{ width: '100%' }}
            // addonBefore="http://"
            // addonAfter=".com"
            value={selectedProduct?.seriNumber}
            onChange={(e) => handleChangeField('seriNumber', e?.target?.value)}
            placeholder="Nhập số sổ"
          />
        </Col>
        <Col span={12}>
          <span className="text-black font-semibold">Hiện trạng</span>
          <Input
            style={{ width: '100%' }}
            value={selectedProduct?.property}
            onChange={(e) => handleChangeField('property', e?.target?.value)}
            placeholder="Nhập Hiện trạng"
          />
        </Col>

      </Row>
      <Row gutter={24} className="mt-4">
        <Col span={12}>
          <span className="text-black font-semibold">Hướng</span>
          <div className="filter-grid__item">
            <Select
              allowClear
              placeholder="Chọn hướng"
              value={selectedProduct?.directionType}
              options={DirectionTypes}
              onChange={(value) => {
                handleChangeField('directionType', value);
              }}
            />
          </div>
        </Col>
        <Col span={12}>
          <span className="text-black font-semibold">Loại sản phẩm</span>
          <div className="filter-grid__item">
            <Select
              allowClear
              placeholder="Loại sản phẩm"
              value={selectedProduct?.typeProduct}
              options={TypeProducts}
              onChange={(value) => {
                handleChangeField('typeProduct', value);
              }}
            />
          </div>
        </Col>
      </Row>
    </>

    const address = <>
      <Row gutter={24}>
        <Col span={12}>
          <span className="product-item text-black font-semibold">Chọn thành phố/Tỉnh</span>
          <div className="filter-grid__item">
            <Select
              allowClear
              showSearch
              placeholder="Thành phố/Tỉnh"
              value={selectedProduct?.address?.cityId}
              filterOption={filterOption}
              options={cities}
              onChange={(value) => {
                handleChangeAddress('cityId', value);
              }}
            />
          </div>
        </Col>
        <Col span={12}>
          <span className="text-black font-semibold">Chọn Quận/Huyện</span>
          <div className="filter-grid__item">
            <Select
              allowClear
              placeholder="Chọn Quận/Huyện"
              value={selectedProduct?.address?.districtId}
              options={districts}
              showSearch
              filterOption={filterOption}
              onChange={(value) => {
                handleChangeAddress('districtId', value);
              }}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={24} className='mt-4'>
        <Col span={12}>
          <span className="text-black font-semibold">Chọn Phường/Xã</span>
          <div className="filter-grid__item">
            <Select
              allowClear
              placeholder="Chọn Phường/Xã"
              showSearch
              filterOption={filterOption}
              value={selectedProduct?.address?.wardId}
              options={wards}
              onChange={(value) => {
                handleChangeAddress('wardId', value);
              }}
            />
          </div>
        </Col>
        <Col span={12}>
          <span className="text-black font-semibold">Địa chỉ</span>
          <Input
            style={{ width: '100%', height: '40px' }}
            value={selectedProduct?.address?.street}
            onChange={(e) => handleChangeAddress('street', e?.target?.value)}
            placeholder="Nhập tên đường"
          />
        </Col>
      </Row>
      <Row className="mt-4 d-flex align-items-center">
        <h3>Lấy thông tin tọa độ</h3>
        <Button
          className='ml-3'
          loading={isSubmitting}
          style={{
            background: 'green',
            color: 'white',
            padding: '12px'
          }}
          onClick={() => getCoordinates(selectedProduct)}
        >
          Call api
        </Button>
      </Row>
      <Row gutter={16} className="mt-4">
        <Col span={12}>
          <span className="text-black font-semibold">Vĩ độ</span>
          <Input
            style={{ width: '100%' }}
            type='number'
            value={selectedProduct?.address?.latitude}
            onChange={(e) => handleChangeAddress('street', e?.target?.value)}
            placeholder="Nhập tên đường"
          />
        </Col>
        <Col span={12}>
          <span className="text-black font-semibold">Kinh độ</span>
          <Input
            style={{ width: '100%' }}
            type='number'
            value={selectedProduct?.address?.longitude}
            onChange={(e) => handleChangeAddress('street', e?.target?.value)}
            placeholder="Nhập tên đường"
          />
        </Col>
      </Row>
    </>

    const owner = <>
      <Row gutter={16}>
        <Col span={12}>
          <span className="text-black font-semibold">Tên chủ nhà</span>
          <Input
            placeholder="Tên chủ nhà"
            style={{ width: '100%' }}
            value={selectedProduct?.owner?.name}
            onChange={(e) => handleChangeOwner('name', null, e)}
          />
        </Col>
        <Col span={12}>
          <span className="product-item text-black font-semibold">
            Số điện thoại chủ nhà
          </span>
          <Input
            placeholder="Nhập SĐT chủ nhà"
            value={selectedProduct?.owner?.phone}
            onChange={(e) => handleChangeOwner('phone', null, e)}
            style={{ width: '100%' }}
          />
        </Col>
        {/* <Col span={24}>
              <span className="text-black font-semibold">Địa chỉ chủ nhà</span>
              <Input
                style={{ width: '100%' }}
                // addonBefore="http://"
                // addonAfter=".com"
                value={selectedProduct?.owner?.address?.street}
                onChange={(e) => handleChangeOwner('address', 'street', e)}
                placeholder="Nhập địa chỉ chủ nhà"
              />
            </Col> */}
        {/* <Col span={12}>
              <span className="text-black font-semibold">Nhân viên</span>
              <Select
                placeholder="Please choose the type"
                style={{ width: '100%' }}
              >
                <Option value="xiao">Khoa Tran</Option>
                <Option value="mao">Tran Khoa</Option>
              </Select>
            </Col> */}
      </Row>
      {/* <Row gutter={16}>
            <Col span={12}>
              <Select placeholder="Please choose the approver">
                <Option value="jack">Jack Ma</Option>
                <Option value="tom">Tom Liu</Option>
              </Select>
            </Col>
            <Col span={12}>
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                getPopupContainer={(trigger) => trigger.parentElement!}
              />
            </Col>
          </Row> */}
      <Row gutter={24} className="mt-4">
        <Col span={12}>
          <span className="text-black font-semibold">Trạng thái</span>
          <div className="filter-grid__item">
            <Select
              allowClear
              placeholder="Chọn trạng thái"
              value={selectedProduct?.status}
              options={StatusProducts}
              onChange={(value) => {
                handleChangeField('status', value);
              }}
            />
          </div>
        </Col>
      </Row>
    </>
    const items: CollapseProps['items'] = [
      {
        key: '1',
        label: 'Thông tin sản phẩm',
        children: <p>{info}</p>,
      },
      {
        key: '2',
        label: 'Địa chỉ',
        children: <p>{address}</p>,
      },
      {
        key: '3',
        label: 'Thông tin chủ nhà',
        children: <p>{owner}</p>,
      },
    ];
    return (
      <Drawer
        title={title}
        width={720}
        onClose={() => onClose()}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        footer={
          <Space style={{ float: 'right' }}>
            <Button
              loading={isSubmitting}
              style={{ height: '50px', width: '100px' }}
              onClick={() => onClose()}
            >
              Hủy
            </Button>
            <Button
              loading={isSubmitting}
              style={{
                height: '50px',
                width: '200px',
                background: 'green',
                color: 'white',
              }}
              // disabled={!hasInputData}
              onClick={() => handleSubmit()}
            >
              Lưu nguồn
            </Button>
          </Space>
        }
      >
        {/* <InputNumber addonAfter="m2" defaultValue={selectedProduct?.acreage}/> */}
        <Form layout="vertical" hideRequiredMark>
          <Collapse defaultActiveKey={['1']} items={items} onChange={() => { }} />
          <Row gutter={16} className="mt-4">
            <Col span={24}>
              <span className="text-black font-semibold">Mô tả</span>
              <Input.TextArea
                rows={8}
                placeholder="Nhập mô tả"
                value={selectedProduct?.description}
                onChange={(e) => {
                  handleChangeField('description', e?.target?.value);
                }}
              />
            </Col>
          </Row>

          <Row gutter={16} className="mt-4">
            <Col span={24}>
              <div className="media-file-header">
                <span className="text-black font-semibold">
                  Hình ảnh & Video
                </span>
                {/* {!!selectedProduct?.produtMediaFiles?.length && (
                  <span
                    className="btn-clear-all-image"
                    onClick={() => {
                      onRemoveFile(selectedProduct?.produtMediaFiles);
                    }}
                  >
                    Xóa tất cả
                  </span>
                )} */}
              </div>
              {_renderMediaFiles()}
            </Col>
          </Row>
        </Form>
      </Drawer>
    );
  };

  const _renderModalWarning = () => {
    return (
      <Modal
        title={'Lưu ý'}
        okText="Đồng ý"
        cancelText="Hủy"
        open={openModalWarning}
        onOk={() => {
          setOpenModalWarning(false);
          onClose(true);
          onRemoveFile(tempFiles);
        }}
        onCancel={() => setOpenModalWarning(false)}
      >
        <p>Bạn có muốn thoát mà không lưu những thay đổi?</p>
      </Modal>
    );
  };

  return (
    <div>
      {_renderSearchBar()}
      {_renderContent()}
      {_renderDrawer()}
      {_renderModalWarning()}
    </div>
  );
};

export default ProductManagement;
